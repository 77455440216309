<script>
import DateInput from './DateInput.vue';
export default {
  components: { DateInput },
  name: "FurloughBox",

  props: {
    furloughData: {
      type: Object,
      reuired: true
    },
  },

  data() {return {
    userData: {
      f_name: "Loading..",
      s_name: "",
      picture: "",
    }
  }},

  computed: {
    statusString() {
      return this.$lan(this.furloughData?.status + "_furlough");
    }
  },

  mounted() {
    this.RequestUserData();
  },

  methods: {
    RequestUserData()
    {
      this.$api.get({path: `/users/${this.furloughData.u_id}`}, this.OnUserResponse)
    },

    OnUserResponse({response})
    {
      const { user } = response.data;
      this.userData = user;
    },

    OpenModal()
    {
      const params = {
        furloughData: this.furloughData,
        userData: this.userData
      };

      this.$modal.Open("FurloughModalView", { context: this, params });
    }
  },
}
</script>


<template>
<div class="box furlough-box click-area" @click="OpenModal">
  <div class="user">
    <avatar :f-name="userData.f_name" :s-name="userData.s_name" :url="userData.picture">
    </avatar>

    <div class="info">
      <span>{{userData.f_name}} {{userData.s_name}}</span>
      <group-plate :id="userData.ugroup" :show-icon="false"></group-plate>
    </div>
  </div>

  <div class="timestamp">
    <span>Cerere facuta:</span>
    <span>{{$utils.TimeStampToString(furloughData.timestamp)}}</span>
  </div>

  <div class="time hide-small-device">
    <date-input :value="furloughData.start_time" disabled></date-input>
    <date-input :value="furloughData.end_time" disabled></date-input>
  </div>

  <div class="status hide-small-device">
    {{statusString}}
  </div>

</div>
</template>


<style scoped>
.furlough-box {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-evenly;
  /* width: fit-content; */
  border-radius: 20px;
  transition: transform .3s;
  padding: 15px;
  gap: 10px;
  overflow: hidden;
}


.furlough-box:hover {
  transform: translateY(-3px);
}

.furlough-box:active {
  outline: solid 1px var(--theme-color);
}

.furlough-box .user {
  padding: 15px;
  display: flex;
  align-items: center;
}

.furlough-box .user .info {
  display: flex;
  flex-direction: column;
  padding: 5px;
  font-weight: bold;
}

.group-plate {
  border: none;
  background: none;
  padding: 0;
  justify-content: left;
}

.group-plate:hover {
  background: none;
}

.furlough-box .time {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.furlough-box .time input {
  border: none;
  background-color: var(--dark-box-color);
}

.furlough-box .timestamp {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  border-radius: 30px;
  background: var(--dark-box-color);
  padding: 15px;
}

.furlough-box .timestamp span {
  width: 100%;
  text-align: center;
  font-weight: bold;
}

.furlough-box .status {
  flex: 1;
  padding: 15px;
  font-weight: bold;
  text-align: center;
}

</style>
<script>
import FurloughBox from '../../components/Common/FurloughBox.vue';
  
export default {
  name: "FurloughPage",

  components: {FurloughBox},

  data() { return {
    type: null,
    furlough_list: []
  }},

  computed: {
    showNoResult() {
      return this.listLength < 1;
    },

    listLength() {
      return this.furlough_list.length;
    }
  },

  created() {
    this.RequestFurloughList();
  },

  methods: {
    RequestFurloughList()
    {
      const params = {
        type: this.type
      };
      this.$api.get({ path: "/furloughs/", params}, this.OnFurloughRequest)
    },

    OnFurloughRequest({response})
    {
      const { furlough_list } = response.data;
      this.furlough_list = furlough_list;
    }
  }
}
</script>

<template>
<div class="furlough-page">

  <div class="furlough-grid">
    <select class="input" v-model="type" @change="RequestFurloughList">
      <option :value="null" selected>Toate</option>
      <option value="short_term">Invoiri</option>
      <option value="long_term">Concedii</option>
    </select>

    <div class="box furlough-noresult" v-if="showNoResult">
      Nu exista cereri de concediu sau invoire inca.
    </div>

    <FurloughBox v-for="(value, index) in furlough_list" :furlough-data="value" :key="index">
    </FurloughBox>
  </div>
</div>
</template>


<style scoped>
.furlough-page {
  display: flex;
  width: 100%;
  justify-content: center;
}

.furlough-grid {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
}

.furlough-noresult {
  padding: 20px;
  border-radius: 30px;
}
</style>